import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  TextInput,
  SelectInput,
  required,
  minLength,
  maxLength,
  email,
  useTranslate,
  ReferenceInput,
  Toolbar,
  NumberInput,
  BooleanInput,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Butto, InputAdornment } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CustomSaveButton from "./CustomSaveButton";
import { useNotify, useRedirect } from "react-admin";

const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const RoomServicesForm = (props) => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));

  const classes = useStyles();
  const translate = useTranslate();
  const { source, ...rest } = props;

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    {translate("ra.label.room_service")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    required
                    resource="services"
                    source="name"
                    label="ra.column.name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <NumberInput min={0} fullWidth required className={classes.formInput} resource="services" source="price" label="Price" InputProps={{
                    startAdornment: <InputAdornment position="start">{authInfo.tenant.locale.currency_code}</InputAdornment>
                  }} />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <NumberInput className={classes.formInput}
                    min={0}
                    fullWidth
                    required
                    resource="services" source="service_charge" format={v => v * 100} parse={v => parseFloat(v) / 100} label="Service Charge" InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }} />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <BooleanInput
                    className={classes.formInput}
                    source="is_active"
                    reference="cm_client_status"
                    defaultValue={true}
                    label="ra.column.status"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}></Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default RoomServicesForm;
