import React, { useState, useContext, useEffect, createContext } from "react";
import {
    FormWithRedirect,
    DateInput,
    TextInput,
    SelectInput,
    SaveButton,
    required,
    minValue,
    maxValue,
    email,
    useTranslate,
    ReferenceInput,
    AutocompleteInput,
    NumberInput,
    FormDataConsumer,
} from "react-admin";
import { computeBreakdown, dateSequence, computeTotalRoomCharges, computeTotalServiceCharges } from '../../services/pricingService';
import { Typography, Box, Toolbar, Button, Chip } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Card, CardContent } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { DataProviderContext } from "react-admin";
import { useNotify, useRedirect } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import RemoveIcon from "@material-ui/icons/Remove";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DoneIcon from "@material-ui/icons/Done";
import CircularProgress from '@material-ui/core/CircularProgress';
import { formatter } from "../../utils/formatter";
import moment from "moment";
import { bookingApi } from "../../api/bookingApi";
import { userApi } from "../../api/userApi";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
    },
    formInput: {
        marginTop: "0!important",
        marginBottom: "0!important",
    },
    textBold: {
        fontWeight: "bold",
    },
}));

const SearchRoomTypeForm = () => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const { setAvailableRoomType,
        selectedBooking,
        setSelectedBooking,
        roomTypes
    } = useContext(BookingFormContext)

    const searchRoomType = (formData) => {
        bookingApi
            .getAvailableRoomTypes(
                authInfo.tenant.id,
                formData.check_in_date,
                formData.check_out_date,
                -1,
                -1
            )
            .then(
                (data) => {
                    setAvailableRoomType(data.data.get_available_room_types.map(row => {
                        const match = roomTypes.find(rt => rt.id == row.id)
                        return { ...row, max_occupancy: match.max_occupancy, default_occupancy: match.default_occupancy }
                    }));
                    setSelectedBooking(formData);
                },
                (error) => {
                    notify(error);
                }
            );
    };
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const handleCheckInDate = (formData) => {
        if (Object.keys(formData).length !== 0) {
            var start_date = new Date(formData.check_in_date);
            var end_date = new Date(formData.check_in_date);
            formData.check_out_date = moment(end_date.setDate(start_date.getDate() + 1)).format("YYYY-MM-DD");
        }
    }

    return (
        <FormWithRedirect
            initialValues={selectedBooking}
            onSubmit={() => { }}
            render={(formProps) => (
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.textBold}
                                        gutterBottom
                                    >
                                        Booking Info
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Booking Source"
                                        source="booking_source"
                                        reference="e_booking_sources"
                                        validate={[required()]}
                                        filter={{ id: ["Reception", "Call", "Email", "Website"] }}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Customer Type"
                                        source="customer_type"
                                        reference="e_customer_types"
                                        validate={[required()]}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            formData.customer_type == "Company" && (
                                                <ReferenceInput
                                                    className={classes.formInput}
                                                    source="company_id"
                                                    reference="companies"
                                                    filter={{ tenant_id: authInfo.tenant.id }}
                                                    sort={{ field: "name", order: "ASC" }}
                                                    filterToQuery={(searchText) => ({
                                                        name: searchText,
                                                    })}
                                                    label="Company"
                                                    fullWidth
                                                    validate={[required()]}
                                                >
                                                    <AutocompleteInput
                                                        optionText="name"
                                                        options={{
                                                            InputProps: { autoComplete: "no" },
                                                        }}
                                                    />
                                                </ReferenceInput>
                                            )
                                        }
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData }) => (
                                            <DateInput
                                                className={classes.formInput}
                                                fullWidth
                                                resource="bookings"
                                                source="check_in_date"
                                                label="Check In Date"
                                                onChange={handleCheckInDate(formData)}
                                                defaultValue={moment(today).format("YYYY-MM-DD")}
                                                validate={[
                                                    required(),
                                                    minValue(moment(new Date()).format("YYYY-MM-DD")),
                                                ]}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <DateInput
                                                className={classes.formInput}
                                                fullWidth
                                                resource="bookings"
                                                source="check_out_date"
                                                label="Check Out Date"
                                                disabled={!formData.check_in_date}
                                                defaultValue={moment(tomorrow).format("YYYY-MM-DD")}
                                                validate={[
                                                    required(),
                                                    minValue(
                                                        moment(
                                                            new Date(formData.check_in_date).setDate(
                                                                new Date(formData.check_in_date).getDate() + 1
                                                            )
                                                        ).format("YYYY-MM-DD")
                                                    ),
                                                ]}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.formGrid}>
                                    <NumberInput
                                        className={classes.formInput}
                                        fullWidth
                                        resource="bookings"
                                        source="adult_qty"
                                        label="Adult Quantity"
                                        defaultValue={1}
                                        min={1}
                                        validate={[required(), minValue(0)]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.formGrid}>
                                    <NumberInput
                                        className={classes.formInput}
                                        fullWidth
                                        resource="bookings"
                                        source="children_qty"
                                        label="Children Quantity"
                                        defaultValue={0}
                                        min={0}
                                        validate={[required(), minValue(0)]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <SaveButton
                                                {...formProps}
                                                variant="contained"
                                                color="primary"
                                                label="Search"
                                                icon={<SearchIcon />}
                                                className={classes.formInput}
                                                onSave={() => searchRoomType(formData)}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        />
    );
};

const BookingItemAvailableList = ({
    selectedVAT
}) => {
    const classes = useStyles();
    const notify = useNotify();
    const {
        roomTypes,
        availableRoomType,
        setSelectedRoomType,
        setAvailableRoomType } = useContext(BookingFormContext)

    const addSelectedRoomType = (row, formData) => {
        var selected_room_type_rate = roomTypes
            .filter((x) => x.id === row.id)[0]
            .room_type_rates.filter(function (item) {
                return item.id === formData.room_type_rate_id;
            });
        debugger
        var data = {
            id: Math.random(),
            room_type_id: row.id,
            room_type_rate_id: formData.room_type_rate_id,
            qty: formData.qty,
            room_type_name: row.name,
            room_type_rate_name: selected_room_type_rate[0]?.room_rate?.name,
            cancellation_policy_name:
                selected_room_type_rate[0]?.room_rate?.cancellation_policy?.name,
            price: selected_room_type_rate[0]?.price,
            additional_adult: formData.additional_adult,
            additional_adult_charge: selected_room_type_rate[0]?.additional_charge_per_adult,
            service_charge: selectedVAT,
        };

        let selectedRoomtype = availableRoomType.filter((x) => x.id == row.id)[0];
        if (selectedRoomtype.available_rooms < formData.qty) {
            notify(
                `Insufficient room quantity, only ${selectedRoomtype.available_rooms} room left.`
            );
        } else {
            let aRoomType = availableRoomType.map((x) => {
                if (x.id == row.id) {
                    x.available_rooms -= formData.qty;
                }
                return x;
            });

            setAvailableRoomType(aRoomType);
            setSelectedRoomType((selectedRoomType) => [...selectedRoomType, data]);
        }
    };



    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                className={classes.textBold}
                                gutterBottom
                            >
                                Rooms Available
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.formGrid}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Availability</TableCell>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>Cancellation policy</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Occupancy</TableCell>
                                            <TableCell>Additional Adult</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {availableRoomType.map((row) => (
                                            <FormWithRedirect
                                                onSubmit={() => { }}
                                                render={(formProps) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell>{row.available_rooms}</TableCell>
                                                        <TableCell>
                                                            <SelectInput
                                                                className={classes.formInput}
                                                                source="room_type_rate_id"
                                                                choices={
                                                                    roomTypes.filter((x) => x.id == row.id)[0]
                                                                        .room_type_rates.filter((x) => x.room_rate.status != 'Inactive')
                                                                }
                                                                optionText="room_rate.name"
                                                                validate={[required()]}
                                                            />
                                                        </TableCell>
                                                        <FormDataConsumer>
                                                            {({ formData, ...rest }) => {
                                                                var selected_room_type_rate = roomTypes
                                                                    .filter((x) => x.id == row.id)[0]
                                                                    .room_type_rates.filter(function (item) {
                                                                        return (
                                                                            item.id == formData.room_type_rate_id
                                                                        );
                                                                    });

                                                                return (
                                                                    <TableCell>
                                                                        {selected_room_type_rate.length > 0
                                                                            ? selected_room_type_rate[0].room_rate
                                                                                .cancellation_policy?.name
                                                                            : "-"}
                                                                    </TableCell>
                                                                );
                                                            }}
                                                        </FormDataConsumer>
                                                        <TableCell align="right">
                                                            <NumberInput
                                                                className={classes.formInput}
                                                                fullWidth
                                                                max={row.available_rooms}
                                                                min={1}
                                                                defaultValue={1}
                                                                source="qty"
                                                                label="Quantity"
                                                                validate={[
                                                                    required(),
                                                                    maxValue(row.available_rooms),
                                                                    minValue(1),
                                                                ]}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <NumberInput disabled={true} className={classes.formInput}
                                                                fullWidth source="occupancy" defaultValue={row.default_occupancy} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <NumberInput disabled={(row.max_occupancy - row.default_occupancy) == 0} className={classes.formInput}
                                                                fullWidth
                                                                defaultValue={0}
                                                                source="additional_adult"
                                                                min={0}
                                                                max={row.max_occupancy - row.default_occupancy}
                                                                validate={[
                                                                    required(), maxValue(row.max_occupancy - row.default_occupancy), minValue(0)
                                                                ]}
                                                            />



                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <FormDataConsumer>
                                                                {({ formData, ...rest }) => (
                                                                    <SaveButton
                                                                        {...formProps}
                                                                        style={{ border: "none" }}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        label="Add"
                                                                        icon={<AddIcon />}
                                                                        className={classes.formInput}
                                                                        onSave={() =>
                                                                            addSelectedRoomType(row, formData)
                                                                        }
                                                                    />
                                                                )}
                                                            </FormDataConsumer>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

const BookingItemAddedList = () => {
    const classes = useStyles();
    const {
        availableRoomType,
        selectedRoomType,
        setSelectedRoomType,
        setAvailableRoomType,
    } = useContext(BookingFormContext)
    const removeSelectedRoomType = (row) => {
        let aRoomType = availableRoomType.map((x) => {
            if (x.id == row.room_type_id) {
                x.available_rooms += row.qty;
            }
            return x;
        });

        setAvailableRoomType(aRoomType);
        setSelectedRoomType(selectedRoomType.filter((item) => item.id !== row.id));
    };
    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                className={classes.textBold}
                                gutterBottom
                            >
                                Rooms Added
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.formGrid}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>Cancellation policy</TableCell>
                                            <TableCell align="right">Quantity</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedRoomType.map((row) => (
                                            <FormWithRedirect
                                                onSubmit={() => { }}
                                                render={(formProps) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">
                                                            {row.room_type_name}
                                                        </TableCell>
                                                        <TableCell>{row.room_type_rate_name}</TableCell>
                                                        <TableCell>
                                                            {row.cancellation_policy_name}
                                                        </TableCell>
                                                        <TableCell align="right">{row.qty}</TableCell>
                                                        <TableCell align="right">
                                                            <FormDataConsumer>
                                                                {({ formData, ...rest }) => (
                                                                    <SaveButton
                                                                        {...formProps}
                                                                        style={{ border: "none", color: "#f44336" }}
                                                                        variant="outlined"
                                                                        color="primary"
                                                                        label="Remove"
                                                                        icon={<RemoveIcon />}
                                                                        className={classes.formInput}
                                                                        onSave={() => removeSelectedRoomType(row)}
                                                                    />
                                                                )}
                                                            </FormDataConsumer>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};


const ChargeSummary = ({ roomCharges, roomServiceCharges, selectedVAT }) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                className={classes.textBold}
                                gutterBottom
                            >
                                Charges Summary
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.formGrid}>
                            <Typography gutterBottom>Room Charges</Typography>
                        </Grid>
                        <Grid item xs={7} className={classes.formGrid}>
                            <Typography gutterBottom align="right">
                                {formatter.formatMoney(
                                    roomCharges,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.formGrid}>
                            <Typography gutterBottom>VAT ({selectedVAT * 100}%)</Typography>
                        </Grid>
                        <Grid item xs={7} className={classes.formGrid}>
                            <Typography gutterBottom align="right">
                                {formatter.formatMoney(
                                    roomServiceCharges,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.formGrid}>
                            <Typography gutterBottom>Total</Typography>
                        </Grid>
                        <Grid item xs={7} className={classes.formGrid}>
                            <Typography gutterBottom align="right">
                                {formatter.formatMoney(
                                    roomServiceCharges + roomCharges,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

const AddGuestForm = ({ setTabValue, setNewGuest, setIsExistingGuest, setSelectedGuest }) => {
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const [guestForm, setGuestForm] = React.useState({});
    const [guestFound, setGuestFound] = React.useState(null);
    const authInfo = JSON.parse(localStorage.getItem("auth"));

    const previousPage = () => {
        setTabValue(0);
    };

    const nextPage = (formData) => {
        setNewGuest(formData);
        setTabValue(2);
    };

    const searchGuest = (formData) => {
        console.log(formData)
        if (Object.keys(formData).length === 0) {
            alert("Please enter document no.");
        }
        else {
            if (formData.search_document_type == null) {
                alert("Pleases select document type");
                return
            }

            userApi
                .searchGuest(
                    authInfo.tenant.id,
                    formData.search_document_type,
                    formData.search_document_no
                )
                .then(
                    (data) => {
                        console.log("guest", data)
                        if (data.data.guests.length === 0) {
                            setGuestFound(false)
                            setIsExistingGuest(false)
                            setSelectedGuest(null)
                            setGuestForm({})
                            return
                        }

                        let guest = data.data.guests[0];

                        setGuestForm({
                            /* Personal Info*/
                            first_name: guest.first_name,
                            last_name: guest.last_name,
                            gender: guest.gender,
                            title: guest.title,
                            nationality: guest.nationality,
                            birth_date: guest.birth_date,

                            /* Contact Info*/
                            email: guest.email,
                            phone_no: guest.phone_no,
                            address_1: guest.address_1,
                            address_2: guest.address_2,
                            zip_code: guest.zip_code,
                            region: guest.region,
                            city: guest.city,
                            country: guest.country,

                            /* Legal Info*/
                            document_type: guest.document_type,
                            document_no: guest.document_no,
                            document_expiry: guest.document_expiry,
                        })

                        setGuestFound(true)
                        setIsExistingGuest(true)
                        setSelectedGuest(guest)
                        // setGuestForm(()=>({first_name:"first_name"}))
                    },
                    (error) => {
                        notify(error);
                    }
                );

        }

    };

    return (
        <FormWithRedirect
            onSubmit={() => { }}
            initialValues={guestForm}
            render={(formProps) => (
                <Grid item xs={12} className={classes.formGrid}>
                    <Card style={{ marginBottom: 20 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.textBold}
                                        gutterBottom
                                    >
                                        Search Guest
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.formGrid}>
                                    <ReferenceInput
                                        source="search_document_type"
                                        reference="e_document_types"
                                        sort={{ field: "comment", order: "ASC" }}
                                        label="ra.column.document_type"
                                        fullWidth
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        fullWidth
                                        source="search_document_no"
                                        label="ra.column.document_no"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} className={classes.formGrid} style={{ alignContent: 'center' }}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<SearchIcon />}
                                                className={classes.formInput}
                                                onClick={() => { searchGuest(formData) }}
                                            >
                                                Search
                                            </Button>
                                        )}
                                    </FormDataConsumer>
                                </Grid>
                            </Grid>

                            {guestFound !== null ?

                                guestFound ?
                                    <Typography variant="body1" style={{ color: 'green', fontWeight: 'bold' }}>Guest Found.</Typography>
                                    :
                                    <Typography variant="body1" style={{ color: 'red', fontWeight: 'bold' }}>Guest Not Found.</Typography>
                                :
                                null
                            }

                        </CardContent>
                    </Card>
                    <Card style={{ marginBottom: 20 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <Typography variant="h6" gutterBottom>
                                        Personal Info
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        className={classes.formInput}
                                        fullWidth
                                        resource="guests"
                                        source="first_name"
                                        label="ra.column.first_name"
                                        validate={[required()]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        className={classes.formInput}
                                        fullWidth
                                        resource="guests"
                                        source="last_name"
                                        label="ra.column.last_name"
                                        validate={[required()]}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        source="gender"
                                        reference="e_genders"
                                        sort={{ field: "comment", order: "ASC" }}
                                        label="ra.column.gender"
                                        fullWidth
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        source="title"
                                        reference="e_titles"
                                        sort={{ field: "comment", order: "ASC" }}
                                        label="ra.column.title"
                                        fullWidth
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        source="nationality"
                                        reference="e_nationalities"
                                        sort={{ field: "comment", order: "ASC" }}
                                        filterToQuery={(searchText) => ({
                                            comment: searchText,
                                        })}
                                        label="ra.column.nationality"
                                        fullWidth
                                    >
                                        <AutocompleteInput
                                            optionText="comment"
                                            options={{
                                                InputProps: { autoComplete: "no" },
                                            }}
                                        />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <DateInput
                                        fullWidth
                                        className={classes.formInput}
                                        resource="guests"
                                        source="birth_date"
                                        label="ra.column.birth_date"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate("ra.label.contact_info")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        fullWidth
                                        resource="guests"
                                        source="email"
                                        type="email"
                                        validate={[required(), email()]}
                                        label="ra.column.email"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <IntlPhoneInput
                                        className={classes.formInput}
                                        resource="guests"
                                        source="phone_no"
                                        label={translate("ra.column.phone_no")}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <TextInput
                                        multiline
                                        fullWidth
                                        resource="guests"
                                        source="address_1"
                                        label="ra.column.address_1"
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <TextInput
                                        multiline
                                        fullWidth
                                        resource="guests"
                                        source="address_2"
                                        label="ra.column.address_2"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        fullWidth
                                        resource="guests"
                                        source="zip_code"
                                        label="ra.column.zip_code"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        fullWidth
                                        resource="guests"
                                        source="region"
                                        label="ra.column.region"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <TextInput
                                        fullWidth
                                        resource="guests"
                                        source="city"
                                        label="ra.column.city"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <ReferenceInput
                                        source="country"
                                        reference="e_countries"
                                        sort={{ field: "comment", order: "ASC" }}
                                        filterToQuery={(searchText) => ({
                                            comment: searchText,
                                        })}
                                        label="ra.column.country"
                                        fullWidth
                                    >
                                        <AutocompleteInput
                                            optionText="comment"
                                            options={{
                                                InputProps: { autoComplete: "no" },
                                            }}
                                        />
                                    </ReferenceInput>
                                </Grid>

                                <Grid item xs={12} className={classes.formGrid}>
                                    <Typography variant="h6" gutterBottom>
                                        {translate("ra.label.legal_info")}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={4} className={classes.formGrid}>
                                    <ReferenceInput
                                        source="document_type"
                                        reference="e_document_types"
                                        sort={{ field: "comment", order: "ASC" }}
                                        label="ra.column.document_type"
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.formGrid}>
                                    <TextInput
                                        fullWidth
                                        resource="guests"
                                        source="document_no"
                                        label="ra.column.document_no"
                                        validate={[required()]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} className={classes.formGrid}>
                                    <DateInput
                                        fullWidth
                                        resource="guests"
                                        source="document_expiry"
                                        label="ra.column.document_expiry"
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Card>
                        <CardContent>
                            <Grid item xs={12} sm={12} className={classes.formGrid}>
                                <Button
                                    style={{ marginRight: 5 }}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<NavigateBeforeIcon />}
                                    className={classes.formInput}
                                    onClick={() => {
                                        previousPage();
                                    }}
                                >
                                    Previous
                                </Button>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            label="Next"
                                            icon={<NavigateNextIcon />}
                                            className={classes.formInput}
                                            onSave={() => nextPage(formData)}
                                        />
                                    )}
                                </FormDataConsumer>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        />
    );
};

const SearchGuestForm = ({ setSelectedGuest }) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);

    const setSelectedGuestFn = (formData) => {
        dataProvider
            .getOne("guests", { id: formData.guest_id })
            .then(({ data }) => {
                setSelectedGuest(data);
            })
            .catch((error) => {
                notify(error);
            });
    };

    return (
        <FormWithRedirect
            onSubmit={() => { }}
            render={(formProps) => (
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h6"
                                        className={classes.textBold}
                                        gutterBottom
                                    >
                                        Search Guest
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={10} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        source="guest_id"
                                        reference="guests"
                                        filter={{ tenant_id: authInfo.tenant.id }}
                                        sort={{ field: "display_name", order: "ASC" }}
                                        filterToQuery={(searchText) => ({
                                            display_name: searchText,
                                        })}
                                        label="Guest"
                                        fullWidth
                                    >
                                        <AutocompleteInput
                                            optionText={(choice) => `${choice.display_name} - ${choice.email}`}
                                            options={{
                                                InputProps: { autoComplete: "no" },
                                            }}
                                            validate={[required()]}
                                        />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} sm={2} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <SaveButton
                                                {...formProps}
                                                variant="contained"
                                                color="primary"
                                                label="Search"
                                                icon={<SearchIcon />}
                                                className={classes.formInput}
                                                onSave={() => setSelectedGuestFn(formData)}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        />
    );
};

const GuestInfo = ({ selectedGuest }) => {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Card style={{ marginBottom: 20 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                className={classes.textBold}
                                gutterBottom
                            >
                                Personal Info
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.formGrid}>
                            <Typography className={classes.textBold} gutterBottom>
                                First Name
                            </Typography>
                            {selectedGuest.first_name}
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.formGrid}>
                            <Typography className={classes.textBold} gutterBottom>
                                Last Name
                            </Typography>
                            {selectedGuest.last_name != null ? selectedGuest.last_name : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Gender
                            </Typography>
                            {selectedGuest.gender != null ? selectedGuest.gender : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Title
                            </Typography>
                            {selectedGuest.title != null ? selectedGuest.title : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Nationality
                            </Typography>
                            {selectedGuest.nationality != null
                                ? selectedGuest.nationality
                                : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Birth Date
                            </Typography>
                            {selectedGuest.birth_date != null
                                ? selectedGuest.birth_date
                                : "-"}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginBottom: 20 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                className={classes.textBold}
                                gutterBottom
                            >
                                Contact Info
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Email
                            </Typography>
                            {selectedGuest.email != null ? selectedGuest.email : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Phone Number
                            </Typography>
                            {selectedGuest.phone_no != null ? selectedGuest.phone_no : "-"}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.textBold} gutterBottom>
                                Address 1
                            </Typography>
                            {selectedGuest.address_1 != null ? selectedGuest.address_1 : "-"}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.textBold} gutterBottom>
                                Address 2
                            </Typography>
                            {selectedGuest.address_2 != null ? selectedGuest.address_2 : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Postal Code
                            </Typography>
                            {selectedGuest.zip_code != null ? selectedGuest.zip_code : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Region
                            </Typography>
                            {selectedGuest.region != null ? selectedGuest.region : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                City
                            </Typography>
                            {selectedGuest.city != null ? selectedGuest.city : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Country
                            </Typography>
                            {selectedGuest.country != null ? selectedGuest.country : "-"}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                className={classes.textBold}
                                gutterBottom
                            >
                                Legal Info
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Document Type
                            </Typography>
                            {selectedGuest.document_type != null
                                ? selectedGuest.document_type
                                : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Document Number
                            </Typography>
                            {selectedGuest.document_no != null
                                ? selectedGuest.document_no
                                : "-"}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography className={classes.textBold} gutterBottom>
                                Document Expiry Date
                            </Typography>
                            {selectedGuest.document_expiry != null
                                ? selectedGuest.document_expiry
                                : "-"}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

// const IsExistingGuestForm = ({ isExistingGuest, setIsExistingGuest }) => {
//   const classes = useStyles();

//   return (
//     <FormWithRedirect
//       onSubmit={() => {}}
//       render={(formProps) => (
//         <Grid item xs={12}>
//           <Card>
//             <CardContent>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} className={classes.formGrid}>
//                   <Typography variant="h6" gutterBottom>
//                     Primary Guest
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} className={classes.formGrid}>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={isExistingGuest}
//                         onChange={(e) => setIsExistingGuest(e.target.checked)}
//                         name="isExistingGuest"
//                         color="primary"
//                       />
//                     }
//                     label="Existing Guest"
//                   />
//                 </Grid>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//       )}
//     />
//   );
// };

const RoomInfo = ({ selectedRoomType }) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                className={classes.textBold}
                                gutterBottom
                            >
                                Room Info
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.formGrid}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Rate</TableCell>
                                            <TableCell>Cancellation policy</TableCell>
                                            <TableCell align="right">Quantity</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedRoomType.map((row) => (
                                            <FormWithRedirect
                                                onSubmit={() => { }}
                                                render={(formProps) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell component="th" scope="row">
                                                            {row.room_type_name}
                                                        </TableCell>
                                                        <TableCell>{row.room_type_rate_name}</TableCell>
                                                        <TableCell>
                                                            {row.cancellation_policy_name}
                                                        </TableCell>
                                                        <TableCell align="right">{row.qty}</TableCell>
                                                    </TableRow>
                                                )}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

const BookingFormContext = createContext({})

const BookingForm = (props) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const translate = useTranslate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useContext(DataProviderContext);

    const { source, ...rest } = props;

    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);

    const [isExistingGuest, setIsExistingGuest] = useState(false);
    const [availableRoomType, setAvailableRoomType] = React.useState([]);
    const [selectedRoomType, setSelectedRoomType] = React.useState([]);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [newGuest, setNewGuest] = useState(null);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [roomTypes, setRoomTypes] = React.useState([]);
    const [saving, setSaving] = React.useState(false);

    const [selectedDates, setSelectedDates] = useState([])
    const [roomCharges, setRoomCharges] = useState(0);
    const [roomServiceCharges, setRoomServiceCharges] = useState(0);
    const [dynamicRoomTypeRates, setDynamicRoomTypeRates] = React.useState({});
    const [roomChargesBreakdown, setRoomChargesBreakdown] = React.useState({});

    const [selectedVAT, setSelectedVAT] = React.useState(0);

    function parseDate(str) {
        var mdy = str.split("-");
        return new Date(mdy[0], mdy[1] - 1, mdy[2]);
    }

    function datediff(first, second) {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    useEffect(() => {
        var tenantRequest = { id: authInfo.tenant.id };
        dataProvider
            .getList("tenants", { filter: { ...tenantRequest } })
            .then(({ data }) => {
                setSelectedVAT(data[0].service_charge / 100)
            })
            .catch((error) => {
                notify(error);
            });
    }, []);

    useEffect(() => {
        var roomTypeRequest = { tenant_id: authInfo.tenant.id };
        dataProvider
            .getList("room_types", { filter: { ...roomTypeRequest } })
            .then(({ data }) => {
                setRoomTypes(data);
            })
            .catch((error) => {
                notify(error);
            });
    }, []);

    useEffect(() => {
        if (selectedBooking) {
            let roomTypeRateIds = roomTypes.flatMap(roomType => roomType.room_type_ratesIds)
            bookingApi.getDynamicRates(roomTypeRateIds, selectedBooking.check_in_date, selectedBooking.check_out_date)
                .then((data) => {

                    /** @type {Array<{room_type_rate_id: string, date: string, value: number}>}*/
                    let _dynamicRates = data.data.room_type_rate_adjustment;
                    setDynamicRoomTypeRates(_dynamicRates.reduce((acc, curr) => {
                        let arr = acc[curr.room_type_rate_id] || [];
                        let rateInfo = { date: curr.date, price: curr.value }
                        return { ...acc, [curr.room_type_rate_id]: [...arr, rateInfo] }
                    }, {}))

                }).catch((error) => notify(error))
        } else {
            setDynamicRoomTypeRates({})
        }
    }, [roomTypes, selectedBooking])
    useEffect(() => {
        if (selectedBooking) {
            setSelectedDates(dateSequence(selectedBooking.check_in_date, selectedBooking.check_out_date))
        } else {
            setSelectedDates([])
        }
    }, [selectedBooking])
    useEffect(() => {
        setRoomCharges(computeTotalRoomCharges(selectedRoomType, selectedDates, dynamicRoomTypeRates));
        setRoomServiceCharges(computeTotalServiceCharges(selectedRoomType, selectedDates, dynamicRoomTypeRates));
        setRoomChargesBreakdown(computeBreakdown(selectedRoomType, selectedDates, dynamicRoomTypeRates));
    }, [selectedDates, selectedRoomType, dynamicRoomTypeRates])


    const validateTab1 = () => {
        if (selectedRoomType.length > 0) {
            setTabValue(1);
        } else {
            notify(
                "Please fill in booking info and add a room before proceed to next step."
            );
        }
    };
    const validateTab2 = () => {
        if (selectedGuest != null) {
            setTabValue(2);
        } else {
            notify("Please search for booking guest.");
        }
    };

    const handleSubmit = () => {
        setSaving(true);
        if (isExistingGuest) {
            createBooking(selectedGuest.id);
        } else {
            dataProvider
                .create("guests", {
                    data: {
                        ...newGuest,
                        tenant_id: authInfo.tenant.id,
                    },
                })
                .then(({ data }) => {
                    createBooking(data.id);
                })
                .catch((error) => {
                    setSaving(false);
                    let message = "Something went wrong.";

                    if (error?.message.includes("duplicate") && error?.message.includes("guests_email_key")) {
                        message = "Fail to create guest, email already been used. Please search for existing guest.";
                    }

                    notify(message, 'error');
                });
        }
    };

    const createBooking = (guestId) => {
        let booking_request = {
            tenant_id: authInfo.tenant.id,
            booking_source: selectedBooking.booking_source,
            customer_type: selectedBooking.customer_type,
            check_in_date: selectedBooking.check_in_date,
            check_out_date: selectedBooking.check_out_date,
            adult_qty: selectedBooking.adult_qty,
            children_qty: selectedBooking.children_qty,
            status: "New",
            guest_id: guestId,
            company_id: selectedBooking.company_id,
            booking_items: {
                data: selectedRoomType.map((item) => {
                    return {
                        tenant_id: authInfo.tenant.id,
                        room_type_id: item.room_type_id,
                        room_type_rate_id: item.room_type_rate_id,
                        price: item.price,
                        service_charge: selectedVAT,
                        qty: item.qty,
                    };
                }),
            },
        };
        dataProvider
            .create("bookings", { data: booking_request })
            .then((bookingData) => {
                selectedRoomType.forEach((item, index) => {
                    let successCount = 0;
                    for (var i = 0; i < item.qty; i++) {
                        let accommodation_request = {
                            tenant_id: authInfo.tenant.id,
                            room_type_id: item.room_type_id,
                            room_type_rate_id: item.room_type_rate_id,
                            check_in_date: selectedBooking.check_in_date,
                            check_out_date: selectedBooking.check_out_date,
                            booking_id: bookingData.data.id,
                            additional_adult: item.additional_adult,
                            status: "New",
                        };
                        //create accommodation
                        dataProvider
                            .create("accommodations", {
                                data: accommodation_request,
                            })
                            .then((accommodationData) => {
                                let breakdown = roomChargesBreakdown[item.room_type_rate_id];
                                const totalPrice = breakdown.flatMap(bd => bd.price).reduce((acc, curr) => acc + curr, 0);
                                //create order
                                let order_request = {
                                    tenant_id: authInfo.tenant.id,
                                    accommodation_id: accommodationData.data.id,
                                    service_id: null,
                                    qty: datediff(
                                        parseDate(selectedBooking.check_in_date),
                                        parseDate(selectedBooking.check_out_date)
                                    ),
                                    price: roomCharges,
                                    service_charge: selectedVAT,
                                    total_price:
                                        totalPrice + totalPrice * selectedVAT,
                                    booking_id: bookingData.data.id,
                                    description:
                                        roomTypes.filter((x) => {
                                            return x.id == item.room_type_id;
                                        })[0].name +
                                        " (" +
                                        selectedBooking.check_in_date +
                                        " - " +
                                        selectedBooking.check_out_date +
                                        ")",
                                    order_type: "Accommodation",
                                    status: "Confirmed",
                                };
                                dataProvider
                                    .create("orders", { data: order_request })
                                    .then(({ data }) => {
                                        successCount += 1;
                                        if (
                                            index == selectedRoomType.length - 1 &&
                                            successCount == item.qty
                                        ) {
                                            redirect(`/bookings`);
                                            notify("Booking created");
                                        }
                                    })
                                    .catch((error) => {
                                        setSaving(false);
                                        notify(error);
                                    });
                            })
                            .catch((error) => {
                                setSaving(false);
                                notify(error);
                            });
                    }
                });
            })
            .catch((error) => {
                setSaving(false);
                notify(error);
            });
    };

    console.log("selectedRoomType", selectedRoomType)
    return (
        <BookingFormContext.Provider value={{ roomTypes, setAvailableRoomType, setSelectedRoomType, selectedRoomType, setSelectedBooking, availableRoomType, roomCharges, roomChargesBreakdown, dynamicRoomTypeRates }}>
            <div>
                <AppBar position="static" color="default">
                    <Tabs
                        value={tabValue}
                        indicatorColor="primary"
                        textColor="primary"
                        aria-label="simple tabs example"
                    >
                        <Tab
                            className={classes.textBold}
                            label="Book a Room"
                            {...a11yProps(0)}
                        />
                        <Tab
                            className={classes.textBold}
                            label="Add Guest Info"
                            {...a11yProps(1)}
                        />
                        <Tab className={classes.textBold} label="Confirm" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={2}>
                        <SearchRoomTypeForm />
                        <BookingItemAvailableList selectedVAT={selectedVAT} />
                        <BookingItemAddedList />
                        <ChargeSummary roomCharges={roomCharges} roomServiceCharges={roomServiceCharges} selectedVAT={selectedVAT} />
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        endIcon={<NavigateNextIcon />}
                                        className={classes.formInput}
                                        onClick={validateTab1}
                                    >
                                        Next
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={2}>
                        {/* <IsExistingGuestForm
            isExistingGuest={isExistingGuest}
            setIsExistingGuest={setIsExistingGuest}
          /> */}
                        <AddGuestForm setTabValue={setTabValue} setNewGuest={setNewGuest} setIsExistingGuest={setIsExistingGuest} setSelectedGuest={setSelectedGuest} />

                        {/* {isExistingGuest && selectedGuest ? (
                            <GuestInfo selectedGuest={selectedGuest} />
                        ) : null}
                        {isExistingGuest && selectedGuest ? (
                            <Grid item xs={12} className={classes.formGrid}>
                                <Card>
                                    <CardContent>
                                        <Button
                                            style={{ marginRight: 5 }}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<NavigateBeforeIcon />}
                                            className={classes.formInput}
                                            onClick={() => {
                                                setTabValue(0);
                                            }}
                                        >
                                            Previous
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            endIcon={<NavigateNextIcon />}
                                            className={classes.formInput}
                                            onClick={validateTab2}
                                        >
                                            Next
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ) : null} */}

                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Grid container spacing={2}>
                        <GuestInfo
                            selectedGuest={isExistingGuest ? selectedGuest : newGuest}
                        />
                        <RoomInfo selectedRoomType={selectedRoomType} />
                        <ChargeSummary roomCharges={roomCharges} roomServiceCharges={roomServiceCharges} selectedVAT={selectedVAT}/>
                        <Grid item xs={12} className={classes.formGrid}>
                            <Card>
                                <CardContent>
                                    <Button
                                        style={{ marginRight: 5 }}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<NavigateBeforeIcon />}
                                        className={classes.formInput}
                                        onClick={() => {
                                            setTabValue(1);
                                        }}
                                    >
                                        Previous
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={
                                            saving ? <CircularProgress color="inherit" style={{ width: 20, height: 20 }} /> : <DoneIcon />
                                        }
                                        className={classes.formInput}
                                        onClick={handleSubmit}
                                        disabled={saving}
                                    >
                                        Confirm
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </TabPanel>
            </div>
        </BookingFormContext.Provider>
    );
};

export default BookingForm;
