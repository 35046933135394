import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const bookingApi = {
  getAvailableRoomTypes,
  getAvailableRooms,
  getAccommodations,
  getMaintenances,
  getDynamicRates,
};

function getAvailableRoomTypes(
  tenantId,
  checkInDate,
  checkOutDate,
  max_adult,
  max_children
) {
  var gql = {
    query: `query {
      get_available_room_types(args: {p_tenant_id: "${tenantId}", p_check_in_date: "${checkInDate}", p_check_out_date: "${checkOutDate}", p_max_adults: "${max_adult}", p_max_children: "${max_children}"}) {
        id
        name
        available_rooms
        max_adults
        max_children
      }
    }`,
  };
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      //Authorization: `Bearer ${apiConfig.getRideumToken()}`,
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => {
      return data;
    });
}

function getAvailableRooms(
  tenantId,
  checkInDate,
  checkOutDate,
  excludeAccommodationId
) {
  var gql = {
    query: `query {
      get_available_rooms(args: {p_tenant_id: "${tenantId}", p_check_in_date: "${checkInDate}", p_check_out_date: "${checkOutDate}", p_exclude_accommodation_id: ${excludeAccommodationId != null ? '"' + excludeAccommodationId + '"' : null
      }}) {
        floor
        id
        name
        room_type_id
        room_type_name
      }
    }`,
  };
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      //Authorization: `Bearer ${apiConfig.getRideumToken()}`,
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => {
      return data;
    });
}

function getAccommodations(tenantId, fromDate, toDate) {
  var gql = {
    query: `query {
      accommodations(where: {tenant_id: {_eq: "${tenantId}"}, check_out_date: {_gt: "${fromDate}"}, check_in_date: {_lt: "${toDate}"}}) {
        booking_id
        check_in_date
        check_out_date
        created_at
        id
        room {
          floor
          id
          name
          status
        }
        room_id
        room_type_id
        room_type_rate_id
        status
        updated_at
        e_accommodation_status {
          comment
          id
        }
        room_type {
          name
        }
        room_type_rate {
          room_rate {
            cancellation_policy {
              after_period_cancel_charge
              created_at
              id
              name
              no_show_cancel_charge
              period_cancel_without_charge
              updated_at
            }
            cancellation_policy_id
            name
            meal {
              name
              created_at
              id
              price
              service_charge
              updated_at
            }
            meal_id
          }
          id
          price
          room_rate_id
          room_type_id
          updated_at
          created_at
        }
        booking {
          booking_no
          accommodations(order_by: {created_at: desc}) {
            booking_id
            check_in_date
            check_out_date
            created_at
            id
            room {
              floor
              id
              name
              status
            }
            room_id
            room_type_id
            room_type_rate_id
            status
            updated_at
            e_accommodation_status {
              comment
              id
            }
            room_type {
              name
              room_type_rates {
                created_at
                id
                price
                room_rate_id
                room_type_id
                updated_at
                room_rate {
                  updated_at
                  name
                  meal_id
                  id
                  created_at
                  cancellation_policy_id
                  cancellation_policy {
                    after_period_cancel_charge
                    created_at
                    id
                    name
                    no_show_cancel_charge
                    period_cancel_without_charge
                    updated_at
                  }
                  meal {
                    created_at
                    id
                    name
                    price
                    service_charge
                    updated_at
                  }
                }
              }
              rooms {
                created_at
                floor
                id
                name
                room_type_id
                status
                clean_status
                updated_at
              }
            }
            room_type_rate {
              room_rate {
                cancellation_policy {
                  after_period_cancel_charge
                  created_at
                  id
                  name
                  no_show_cancel_charge
                  period_cancel_without_charge
                  updated_at
                }
                cancellation_policy_id
                name
                meal {
                  name
                  created_at
                  id
                  price
                  service_charge
                  updated_at
                }
                meal_id
              }
              id
              price
              room_rate_id
              room_type_id
              updated_at
              created_at
            }
          }
          orders(order_by: {created_at: desc})  {
            accommodation_id
            booking_id
            invoice_id
            created_at
            description
            id
            order_no
            order_type
            price
            qty
            updated_at
            total_price
            status
            service_id
            service_charge
            e_order_status {
              comment
              id
            }
            accommodation {
              room {
                name
              }
              id
            }
          }
          guest {
            zip_code
            updated_at
            title
            region
            phone_no
            nationality
            last_name
            id
            gender
            first_name
            display_name
            email
            bleudine_user_id
            e_title {
              comment
              id
            }
            e_gender {
              comment
              id
            }
            e_nationality {
              comment
              id
            }
            e_document_type {
              comment
              id
            }
            e_country {
              comment
              id
            }
            document_type
            document_no
            document_front_image_url
            document_expiry
            document_back_image_url
            created_at
            country
            comment
            city
            birth_date
            address_2
            address_1
          }
        }
      }
    }`,
  };
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      //'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => {
      return data;
    });
}

function getMaintenances(tenantId, fromDate, toDate) {
  var gql = {
    query: `query {
      maintenances(where: {tenant_id: {_eq: "${tenantId}"}, end_date: {_gt: "${fromDate}"}, start_date: {_lt: "${toDate}"}}) {
        created_at
        end_date
        id
        room_id
        start_date
        title
        updated_at
        room {
          name
          status
          room_type_id
        }
      }
    }`,
  };
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      //Authorization: `Bearer ${apiConfig.getRideumToken()}`,
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => {
      return data;
    });
}


function query(gql) {
  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      //Authorization: `Bearer ${apiConfig.getRideumToken()}`,
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => {
      return data;
    });

}

/*get dynamic rate with a given range of date
* return a list of dynamic rates with date and value
*/
function getDynamicRates(roomTypeRateIds, fromDate, toDate) {
  const gql = {
    query: `query {
		room_type_rate_adjustment(where: {room_type_rate_id:{_in: ${JSON.stringify(roomTypeRateIds)}}, date:{_lt: "${toDate}",_gte: "${fromDate}"}}){
			room_type_rate_id
			value
			date
		}
	}` }
  return query(gql)
}
