import gql from 'graphql-tag';

export const GET_LIST_TENANTS = gql`
{
  id
  service_charge
  name
  email
  phone_no
  address_1
  address_2
  region
  city
  zip_code
  country
  e_country {
    id
    comment
  }
  status
  e_tenant_status {
    id
    comment
  }
  locale_id
  locale {
    currency_code
  }
  created_at
  updated_at
  tenant_group_id
}
`;
