import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  TextInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  downloadCSV,
} from "react-admin";
import { bookingStatus } from "../../models/bookingStatus";
import { formatter } from "../../utils/formatter";
import Chip from "@material-ui/core/Chip";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import jsonExport from "jsonexport/dist";
import moment from "moment";


const exporter = (bookings) => {
  const forExport = bookings.map((booking) => {
    return {
      booking_no: formatter.bookingNo(booking.booking_no),
      guest: booking.guest.display_name,
      check_in_date: booking.check_in_date,
      check_out_date: booking.check_out_date,
      created_date: moment(booking.created_at).format("DD MMM YYYY hh:mm:ss A"),
      status: booking.e_booking_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: [
        "booking_no",
        "guest",
        "check_in_date",
        "check_out_date",
        "created_date",
        "status",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "bookings");
    }
  );
};

const BookingList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));

  const filters = [
    <TextInput
      type="number"
      source="booking_no"
      label="Booking No"
      alwaysOn
      resettable
      InputProps={{
        startAdornment: <InputAdornment>B</InputAdornment>,
        endAdornment: (
          <InputAdornment>
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
    />,
    // <ReferenceInput
    //   source="guest_id"
    //   reference="guests"
    //   filter={{ tenant_id: authInfo?.tenant?.id }}
    //   sort={{ field: "display_name", order: "ASC" }}
    //   filterToQuery={(searchText) => ({
    //     display_name: searchText,
    //   })}
    //   label="Guest"
    //   alwaysOn
    //   resettable
    // >
    //   <AutocompleteInput
    //     optionText={(choice) =>
    //       choice.id != null ? `${choice.display_name} - ${choice.email}` : ""
    //     }
    //     options={{
    //       InputProps: { autoComplete: "no" },
    //     }}
    //   />
    // </ReferenceInput>,
    <DateInput
      style={{ width: 250 }}
      source="check_in_date"
      label="Check In Date"
      alwaysOn
    />,
    <DateInput
      style={{ width: 250 }}
      source="check_out_date"
      label="Check Out Date"
      alwaysOn
    />,
    <ReferenceInput
      fullWidth
      label="Status"
      source="status"
      reference="e_booking_status"
      alwaysOn
      resettable
      filter={ { comment: ["Confirmed","New","Checked In","Checked Out"] }}
    >
      <SelectInput optionText="comment" />
    </ReferenceInput>,
  ];

  return (
    <List
      filters={filters}
      filterDefaultValues={{ tenant_id: authInfo?.tenant?.id, is_valid: true }}
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <FunctionField
          sortBy="booking_no"
          label="Booking No"
          render={(record) => formatter.bookingNo(record.booking_no)}
        />
        <TextField source="guest.display_name" label="Guest" />
        <TextField source="check_in_date" label="Check In Date" />
        <TextField source="check_out_date" label="Check Out Date" />
        <FunctionField
          sortBy="created_at"
          label="Created Date"
          render={(record) =>
            moment(record.created_at).format("DD MMM YYYY hh:mm:ss A")
          }
        />
        <FunctionField
          sortBy="e_booking_status.comment"
          label="Status"
          render={(record) => (
            <Chip
              label={record.e_booking_status.comment}
              style={{
                color: "white",
                background: bookingStatus.getColor(record.e_booking_status.id),
              }}
            />
          )}
        />
        <EditButton style={{ float: "right" }} basePath="/bookings" />
      </Datagrid>
    </List>
  );
}

export default BookingList;
