import gql from 'graphql-tag';

const GET_LIST_BOOKINGS = gql`
{
  is_valid
  adult_qty
  booking_no
  booking_source
  check_in_date
  check_out_date
  children_qty
  company_id
  created_at
  customer_type
  guest_id
  id
  status
  payment_token_id
  updated_at
  deposits {
    received_currency
    received_amount
    amount
    booking_id
    created_at
    deposit_no
    payment_transaction_id
    id
    status
    tenant_id
    type
    updated_at
    e_deposit_status {
      comment
      id
    }
    e_deposit_type {
      comment
      id
    }
    refunds(order_by: {created_at: desc}) {
      amount
      booking_id
      created_at
      deposit_id
      id
      order_id
      refund_no
      status
      tenant_id
      type
      updated_at
      e_refund_status {
        comment
        id
      }
      e_refund_type {
        comment
        id
      }
    }
    invoice_payments(order_by: {created_at: desc}) {
      amount
      received_currency
      created_at
      credit_card_number
      e_payment_status {
        comment
        id
      }
      e_payment_type {
        id
        comment
      }
      id
      invoice_id
      payment_no
      payment_type
      deposit_id
      payment_transaction_id
      remarks
      status
      updated_at
    }
  }
  refunds (order_by: {created_at: desc}) {
    amount
    booking_id
    created_at
    deposit_id
    id
    order_id
    refund_no
    status
    tenant_id
    type
    updated_at
    deposit {
      deposit_no
    }
    e_refund_status {
      comment
      id
    }
    e_refund_type {
      comment
      id
    }
    order {
      order_no
    }
    beneficiary_acc_no
    beneficiary_bank_name
    beneficiary_name
    remarks
  }
  invoices(order_by: {created_at: desc}) {
    booking_id
    created_at
    e_invoice_status {
      comment
      id
    }
    id
    invoice_no
    status
    subtotal
    tax
    total
    updated_at
    invoice_payments(order_by: {created_at: desc}) {
      amount
      received_currency
      created_at
      credit_card_number
      e_payment_status {
        comment
        id
      }
      e_payment_type {
        id
        comment
      }
      id
      invoice_id
      payment_no
      payment_type
      remarks
      status
      updated_at
    }
       invoice_refunds(order_by: {created_at: desc}) {
    id
    refund_id
    refund_no
    tenant_id
    booking_id
    order_id
    deposit_id
    refund_type
    amount
    status
    created_at
    updated_at
    beneficiary_name
    beneficiary_acc_no
    remarks
    invoice_id
  }
    orders(order_by: {created_at: desc}) {
      tenant_id
      accommodation_id
      booking_id
      created_at
      description
      id
      invoice_id
      order_no
      order_type
      price
      qty
      service_charge
      service_id
      status
      total_price
      updated_at
    }
    invoice_items(order_by: {created_at: desc}) {
      accommodation_id
      booking_id
      created_at
      description
      id
      invoice_id
      order_no
      order_type
      price
      qty
      service_charge
      service_id
      order_id
      total_price
      updated_at
      accommodation {
        room {
          name
        }
        id
      }
    }
  }
  orders(order_by: {created_at: desc})  {
    tenant_id
    accommodation_id
    booking_id
    invoice_id
    created_at
    description
    id
    order_no
    order_type
    price
    qty
    updated_at
    total_price
    status
    service_id
    service_charge
    e_order_status {
      comment
      id
    }
    accommodation {
      room {
        name
      }
      id
    }
    refunds(order_by: {created_at: desc}) {
      amount
      booking_id
      created_at
      deposit_id
      id
      order_id
      refund_no
      status
      tenant_id
      type
      updated_at
      e_refund_status {
        comment
        id
      }
      e_refund_type {
        comment
        id
      }
    }
  }
  accommodations(order_by: {created_at: desc}) {
    booking_id
    check_in_date
    check_out_date
    created_at
    id
    room {
      floor
      id
      name
      status
    }
    room_id
    room_type_id
    room_type_rate_id
    status
    updated_at
    e_accommodation_status {
      comment
      id
    }
    room_type {
      name
      room_type_rates {
        created_at
        id
        price
        room_rate_id
        room_type_id
        updated_at
        room_rate {
          updated_at
          name
          meal_id
          id
          created_at
          cancellation_policy_id
          cancellation_policy {
            after_period_cancel_charge
            created_at
            id
            name
            no_show_cancel_charge
            period_cancel_without_charge
            updated_at
          }
          meal {
            created_at
            id
            name
            price
            service_charge
            updated_at
          }
        }
      }
      rooms {
        created_at
        floor
        id
        name
        room_type_id
        status
        clean_status
        updated_at
      }
    }
    room_type_rate {
      room_rate {
        cancellation_policy {
          after_period_cancel_charge
          created_at
          id
          name
          no_show_cancel_charge
          period_cancel_without_charge
          updated_at
        }
        cancellation_policy_id
        name
        meal {
          name
          created_at
          id
          price
          service_charge
          updated_at
        }
        meal_id
      }
      id
      price
      room_rate_id
      room_type_id
      updated_at
      created_at
    }
  }
  booking_items {
    booking_id
    created_at
    id
    price
    qty
    room_type_id
    room_type_rate_id
    service_charge
    updated_at
    room_type {
      area
      bathroom_facilities
      bedroom_facilities
      created_at
      default_occupancy
      description
      entertainment_facilities
      floor_facilities
      id
      kitchen_facilities
      max_adults
      max_children
      e_room_type {
        comment
        id
      }
      max_occupancy
      name
      outdoor_facilities
      room_facilities
      type
      updated_at
      view_facilities
      room_type_beds {
        created_at
        id
        length
        mattress_type
        qty
        room_type_id
        updated_at
        width
        e_mattress_type {
          comment
          id
        }
      }
      room_type_rates {
        created_at
        id
        room_rate_id
        room_type_id
        updated_at
        price
        room_rate {
          cancellation_policy_id
          created_at
          id
          cancellation_policy {
            after_period_cancel_charge
            created_at
            id
            name
            no_show_cancel_charge
            period_cancel_without_charge
            updated_at
          }
          meal_id
          name
          meal {
            created_at
            id
            name
            price
            service_charge
            updated_at
          }
          updated_at
        }
      }
      rooms {
        updated_at
        status
        clean_status
        room_type_id
        name
        id
        floor
        e_room_status {
          comment
          id
        }
        e_room_clean_status {
          comment
          id
        }
        created_at
      }
    }
  }
  company {
    address_1
    address_2
    city
    comment
    country
    created_at
    e_country {
      comment
      id
    }
    email
    id
    name
    phone_no
    region
    updated_at
    zip_code
  }
  e_booking_source {
    comment
    id
  }
  e_booking_status {
    comment
    id
  }
  e_customer_type {
    comment
    id
  }
  payments(order_by: {created_at: desc}) {
      amount
      received_currency
      created_at
      credit_card_number
      e_payment_status {
        comment
        id
      }
      e_payment_types {
        id
        comment
      }
      id
      payment_no
      payment_type
      deposit_id
      booking_id
      tenant_id
      payment_transaction_id
      remarks
      status
      updated_at
    }
  guest {
    address_1
    address_2
    birth_date
    city
    comment
    country
    created_at
    document_back_image_url
    document_expiry
    document_front_image_url
    document_no
    document_type
    email
    first_name
    display_name
    gender
    id
    last_name
    nationality
    phone_no
    region
    title
    updated_at
    zip_code
    bleudine_user_id
    e_country {
      comment
      id
    }
    e_document_type {
      comment
      id
    }
    e_gender {
      comment
      id
    }
    e_nationality {
      comment
      id
    }
    e_title {
      comment
      id
    }
  }
}
`;

const GET_ONE_BOOKINGS = gql`
{
  is_valid
  adult_qty
  booking_no
  booking_source
  check_in_date
  check_out_date
  children_qty
  company_id
  created_at
  customer_type
  guest_id
  id
  status
  payment_token_id
  updated_at
  cm_client {
    id
    name
  }
  cm_itenerary_no
  deposits(order_by: {created_at: desc}) {
    received_currency
    received_amount
    amount
    booking_id
    created_at
    deposit_no
    payment_transaction_id
    id
    status
    tenant_id
    type
    updated_at
    e_deposit_status {
      comment
      id
    }
    e_deposit_type {
      comment
      id
    }
    refunds(order_by: {created_at: desc}) {
      amount
      booking_id
      created_at
      deposit_id
      id
      order_id
      refund_no
      status
      tenant_id
      type
      updated_at
      e_refund_status {
        comment
        id
      }
      e_refund_type {
        comment
        id
      }
    }
    invoice_payments(order_by: {created_at: desc}) {
      payment_id
      amount
      received_currency
      created_at
      credit_card_number
      e_payment_status {
        comment
        id
      }
      e_payment_type {
        id
        comment
      }
      id
      invoice_id
      payment_no
      payment_type
      deposit_id
      payment_transaction_id
      remarks
      status
      updated_at
    }
  }
  refunds (order_by: {created_at: desc}) {
    amount
    booking_id
    created_at
    deposit_id
    id
    order_id
    refund_no
    status
    tenant_id
    type
    updated_at
    deposit {
      deposit_no
    }
    e_refund_status {
      comment
      id
    }
    e_refund_type {
      comment
      id
    }
    order {
      order_no
    }
    beneficiary_acc_no
    beneficiary_bank_name
    beneficiary_name
    remarks
  }
  invoices(order_by: {created_at: desc}) {
    booking_id
    created_at
    e_invoice_status {
      comment
      id
    }
    id
    invoice_no
    status
    subtotal
    tax
    total
    updated_at
    invoice_payments(order_by: {created_at: desc}) {
      payment_id
      amount
      received_currency
      created_at
      credit_card_number
      e_payment_status {
        comment
        id
      }
      e_payment_type {
        id
        comment
      }
      id
      invoice_id
      payment_no
      payment_type
      remarks
      status
      updated_at
    }
       invoice_refunds(order_by: {created_at: desc}) {
    id
    refund_id
    refund_no
    tenant_id
    booking_id
    order_id
    deposit_id
    refund_type
    amount
    status
    created_at
    updated_at
    beneficiary_name
    beneficiary_acc_no
    remarks
    invoice_id
  }
    orders(order_by: {created_at: desc}) {
      tenant_id
      accommodation_id
      booking_id
      created_at
      description
      id
      invoice_id
      order_no
      order_type
      price
      qty
      service_charge
      service_id
      status
      total_price
      updated_at
    }
    invoice_items(order_by: {created_at: desc}) {
      accommodation_id
      booking_id
      created_at
      description
      id
      invoice_id
      order_no
      order_type
      price
      qty
      service_charge
      service_id
      order_id
      total_price
      updated_at
      accommodation {
        room {
          name
        }
        id
      }
    }
  }
  orders(order_by: {created_at: desc})  {
    tenant_id
    accommodation_id
    booking_id
    invoice_id
    created_at
    description
    id
    order_no
    order_type
    price
    qty
    updated_at
    total_price
    status
    service_id
    service_charge
    e_order_status {
      comment
      id
    }
    accommodation {
      room {
        name
      }
      id
    }
    refunds(order_by: {created_at: desc}) {
      amount
      booking_id
      created_at
      deposit_id
      id
      order_id
      refund_no
      status
      tenant_id
      type
      updated_at
      e_refund_status {
        comment
        id
      }
      e_refund_type {
        comment
        id
      }
    }
  }
  accommodations(order_by: {created_at: desc}) {
    booking_id
    check_in_date
    check_out_date
    created_at
    id
    room {
      floor
      id
      name
      status
    }
    room_id
    room_type_id
    room_type_rate_id
    status
    updated_at
    e_accommodation_status {
      comment
      id
    }
    room_type {
      name
      room_type_rates {
        created_at
        id
        price
        room_rate_id
        room_type_id
        updated_at
        room_rate {
          updated_at
          name
          meal_id
          id
          created_at
          cancellation_policy_id
          cancellation_policy {
            after_period_cancel_charge
            created_at
            id
            name
            no_show_cancel_charge
            period_cancel_without_charge
            updated_at
          }
          meal {
            created_at
            id
            name
            price
            service_charge
            updated_at
          }
        }
      }
      rooms {
        created_at
        floor
        id
        name
        room_type_id
        status
        clean_status
        updated_at
      }
    }
    room_type_rate {
      room_rate {
        cancellation_policy {
          after_period_cancel_charge
          created_at
          id
          name
          no_show_cancel_charge
          period_cancel_without_charge
          updated_at
        }
        cancellation_policy_id
        name
        meal {
          name
          created_at
          id
          price
          service_charge
          updated_at
        }
        meal_id
      }
      id
      price
      room_rate_id
      room_type_id
      updated_at
      created_at
    }
  }
  booking_items {
    booking_id
    created_at
    id
    price
    qty
    room_type_id
    room_type_rate_id
    service_charge
    updated_at
    room_type {
      area
      bathroom_facilities
      bedroom_facilities
      created_at
      default_occupancy
      description
      entertainment_facilities
      floor_facilities
      id
      kitchen_facilities
      max_adults
      max_children
      e_room_type {
        comment
        id
      }
      max_occupancy
      name
      outdoor_facilities
      room_facilities
      type
      updated_at
      view_facilities
      room_type_beds {
        created_at
        id
        length
        mattress_type
        qty
        room_type_id
        updated_at
        width
        e_mattress_type {
          comment
          id
        }
      }
      room_type_rates {
        created_at
        id
        room_rate_id
        room_type_id
        updated_at
        price
        room_rate {
          cancellation_policy_id
          created_at
          id
          cancellation_policy {
            after_period_cancel_charge
            created_at
            id
            name
            no_show_cancel_charge
            period_cancel_without_charge
            updated_at
          }
          meal_id
          name
          meal {
            created_at
            id
            name
            price
            service_charge
            updated_at
          }
          updated_at
        }
      }
      rooms {
        updated_at
        status
        clean_status
        room_type_id
        name
        id
        floor
        e_room_status {
          comment
          id
        }
        e_room_clean_status {
          comment
          id
        }
        created_at
      }
    }
  }
  company {
    address_1
    address_2
    city
    comment
    country
    created_at
    e_country {
      comment
      id
    }
    email
    id
    name
    phone_no
    region
    updated_at
    zip_code
  }
  e_booking_source {
    comment
    id
  }
  e_booking_status {
    comment
    id
  }
  e_customer_type {
    comment
    id
  }
  payments(order_by: {created_at: desc}) {
      amount
      received_currency
      created_at
      credit_card_number
      e_payment_status {
        comment
        id
      }
      e_payment_types {
        id
        comment
      }
      id
      payment_no
      payment_type
      deposit_id
      booking_id
      tenant_id
      payment_transaction_id
      remarks
      status
      updated_at
    }
  guest {
    address_1
    address_2
    birth_date
    city
    comment
    country
    created_at
    document_back_image_url
    document_expiry
    document_front_image_url
    document_no
    document_type
    email
    first_name
    display_name
    gender
    id
    last_name
    nationality
    phone_no
    region
    title
    updated_at
    zip_code
    bleudine_user_id
    e_country {
      comment
      id
    }
    e_document_type {
      comment
      id
    }
    e_gender {
      comment
      id
    }
    e_nationality {
      comment
      id
    }
    e_title {
      comment
      id
    }
  }
}
`;

export { GET_LIST_BOOKINGS, GET_ONE_BOOKINGS }
